/* RIBBON BANNERS */

.ribbon {
	position: absolute;
	top: 0;
	right: 0;
}

/* reset certain elements (in case of conflicting CSS for classes, links, etc.) */
.ribbon .text,
.ribbon .banner,
.ribbon a {
	margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

.ribbon a {
	color: #fff;
	text-decoration: none;
}

.no-css-transforms .ribbon {
	font-size: 1em;
	position: relative;
	width: 100%;
}

.ribbon .banner {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	color: #fff;
	-webkit-font-smoothing: antialiased;
	display: block;
	float: right;
	position: relative;
	right: 0px;
	top: 22px;
	width: 100px;
}

.ribbon-small .banner {
	right: -8px;
	top: 15px;
	width: 65px;
}

.ribbon-large .banner {
	right: -20px;
	top: 32px;
	width: 150px;
}

.ribbon .banner::after,
.ribbon .banner::before {
	content: '';
	display: block;
	height: 12px;
	position: absolute;
	width: 30px;
}

.ribbon-small .banner::after,
.ribbon-small .banner::before {
	height: 6px;
	width: 20px;
}

.ribbon-large .banner::after,
.ribbon-large .banner::before {
	height: 18px;
	width: 45px;
}

.ribbon .banner::before {
	-webkit-transform: skewY(-45deg) translate(50%,15px);
	-moz-transform: skewY(-45deg) translate(50%,15px);
	-ms-transform: skewY(-45deg) translate(50%,15px);
	-o-transform: skewY(-45deg) translate(50%,15px);
	-webkit-transform-origin: 100% center;
	-moz-transform-origin: 100% center;
	-ms-transform-origin: 100% center;
	-o-transform-origin: 100% center;
	left: -45px;
}

.ribbon-small .banner::before {
	top: -5px;
	left: -30px;
}

.ribbon-large .banner::before {
	top: 9px;
	left: -68px;
}

.ribbon .banner::after {
	-webkit-transform: translate(100%,-100%) skewY(45deg) translateX(-58%);
	-moz-transform: translate(100%,-100%) skewY(45deg) translateX(-58%);
	-ms-transform: translate(100%,-100%) skewY(45deg) translateX(-58%);
	-o-transform: translate(100%,-100%) skewY(45deg) translateX(-58%);
	-webkit-transform-origin: 0 center;
	-moz-transform-origin: 0 center;
	-ms-transform-origin: 0 center;
	-o-transform-origin: 0 center;
	right: -17px;
}

.ribbon-small .banner::after {
	top: 18px;
	right: -12px;
}

.ribbon-large .banner::after {
	top: 45px;
	right: -26px;
}


.no-css-transforms .ribbon .banner {
	position: static;
	width: 100%;
	float: none;
	font-size: 10px;
}

.ribbon .text {
	position: relative;
	z-index: 2;
	padding: 6px 0px;
	font-size: 14px;
	font-weight: bold;
	min-height: 18px;
	line-height: 18px;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.20);
	white-space: nowrap;
	text-overflow: ellipsis;
}

/* small text */
.ribbon-small .text {
	padding: 3px 0;
	font-size: 8px;
	min-height: 14px;
	line-height: 14px;
}

/* large text */
.ribbon-large .text {
	padding: 9px 0;
	font-size: 18px;
	min-height: 28px;
	line-height: 28px;
}


.ribbon .text::before,
.ribbon .text::after {
	content: '';
	display: block;
	height: 30px;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -1;
}

/* small text */
.ribbon.ribbon-small .text::before,
.ribbon.ribbon-small .text::after {
	height: 20px;
}

/* large text */
.ribbon.ribbon-large .text::before,
.ribbon.ribbon-large .text::after {
	height: 46px;
}

.ribbon .text::before {
	-webkit-transform: translateX(-15%) skewX(-45deg);
	-moz-transform: translateX(-15%) skewX(-45deg);
	-ms-transform: translateX(-15%) skewX(-45deg);
	-o-transform: translateX(-15%) skewX(-45deg);
}

.ribbon .text::after {
	-webkit-transform: translateX(15%) skewX(45deg);
	-moz-transform: translateX(15%) skewX(45deg);
	-ms-transform: translateX(15%) skewX(45deg);
	-o-transform: translateX(15%) skewX(45deg);
}

.no-css-transforms .ribbon .text {
	height: 25px;
	padding: 3px;
}


/*--- RIBBON COLORS ---*/

/* default */
.ribbon .banner::after,
.ribbon .banner::before {
	background-color: #51a351;
}
.ribbon .text::before,
.ribbon .text::after,
.no-css-transforms .ribbon .text {
	background-color: #62c462;
}

/* green */
.ribbon-green .banner::after,
.ribbon-green .banner::before {
	background-color: #51a351;
}
.ribbon-green .text::before,
.ribbon-green .text::after,
.no-css-transforms .ribbon-green .text {
	background-color: #62c462;
}

/* blue */
.ribbon-blue .banner::after,
.ribbon-blue .banner::before {
	background-color: #0044cc;
}
.ribbon-blue .text::before,
.ribbon-blue .text::after,
.no-css-transforms .ribbon-blue .text {
	background-color: #0088cc;
}

/* mms blue */
.ribbon-blue-mms .banner::after,
.ribbon-blue-mms .banner::before {
	background-color: #2d5b98;
}
.ribbon-blue-mms .text::before,
.ribbon-blue-mms .text::after,
.no-css-transforms .ribbon-blue-mms .text {
	background-color: #367ab3;
}

/* light blue */
.ribbon-blue-light .banner::after,
.ribbon-blue-light .banner::before {
	background-color: #2f96b4;
}
.ribbon-blue-light .text::before,
.ribbon-blue-light .text::after,
.no-css-transforms .ribbon-blue-light .text {
	background-color: #5bc0de;
}

/* yellow */
.ribbon-yellow .banner::after,
.ribbon-yellow .banner::before {
	background-color: #f2ba00;
}
.ribbon-yellow .text::before,
.ribbon-yellow .text::after,
.no-css-transforms .ribbon-yellow .text {
	background-color: #ffd95e;
}

/* orange */
.ribbon-orange .banner::after,
.ribbon-orange .banner::before {
	background-color: #f89406;
}
.ribbon-orange .text::before,
.ribbon-orange .text::after,
.no-css-transforms .ribbon-orange .text {
	background-color: #fbb450;
}

/* red */
.ribbon-red .banner::after,
.ribbon-red .banner::before {
	background-color: #bd362f;
}
.ribbon-red .text::before,
.ribbon-red .text::after,
.no-css-transforms .ribbon-red .text {
	background-color: #ee5f5b;
}

/* purple */
.ribbon-purple .banner::after,
.ribbon-purple .banner::before {
	background-color: #611bbd;
}
.ribbon-purple .text::before,
.ribbon-purple .text::after,
.no-css-transforms .ribbon-purple .text {
	background-color: #af4ce8;
}

/* pink */
.ribbon-pink .banner::after,
.ribbon-pink .banner::before {
	background-color: #f000c0;
}
.ribbon-pink .text::before,
.ribbon-pink .text::after,
.no-css-transforms .ribbon-pink .text {
	background-color: #ff5edf;
}

/* black */
.ribbon-black .banner::after,
.ribbon-black .banner::before {
	background-color: #222222;
}
.ribbon-black .text::before,
.ribbon-black .text::after,
.no-css-transforms .ribbon-black .text {
	background-color: #444444;
}
