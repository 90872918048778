.Video {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 50px;
}

.Video p {
    color: white;
    /* padding-bottom: 24px; */
}

.Video iframe {
    box-sizing: border-box;
    border-radius: 6px;
    border-width: 2px;
    border-color: darkgrey;
}

.VideoTitle {
    font-size: 36px;
    font-weight: bold;
    color: white;
}

@media (max-width: 812px) {
    .Video {
        text-align: center;
        padding-top: 20px;
    }

    .Video iframe {
        max-width: 300px;
    }
}
