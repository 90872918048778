.Documents {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 100px;
}

.Documents p {
    color: white;
    padding-bottom: 24px;
    padding-top: 24px;
}

.DocumentsArticle {
    text-transform: uppercase;
    font-family: "Futura PT Light", sans-serif;
    letter-spacing: 1px;
    color: white;
    display: grid;
}

.DocumentsArticle img {
    width: 600px;
    box-shadow: 6px 6px 3px #333333;
}

.DocumentsLeadSheets {
    text-transform: uppercase;
    font-family: "Futura PT Light", sans-serif;
    letter-spacing: 1px;
    color: white;
    display: grid;
}

.DocumentsLeadSheets img {
    width: 200px;
    box-shadow: 6px 6px 3px #333333;
}

.Documents a,
.Documents a:hover,
.Documents a:active,
.Documents a.active {
    color: white;
    text-decoration: underline;
    text-decoration-color: dimgray;
}

.DocumentsLeadSheetList {
    display: grid;
    padding-top: 24px;
}

.DocumentsLeadSheetList a {
    padding-top: 3px;
}

.DocumentsTitle {
    font-size: 36px;
    font-weight: bold;
    color: white;
    /* padding-bottom: 20px; */
}

.Social {
    text-transform: uppercase;
    font-family: "Futura PT Light", sans-serif;
    letter-spacing: 1px;
    color: white;
}

.Social img {
    width: 30px;
    margin-left: 3px;
    margin-right: 3px;
}

.Social a {
    filter: brightness(1);
    transition: all 200ms ease;
}

.Social a:hover {
    filter: brightness(2);
    transition: all 200ms ease;
}

/*
@media (max-width: 812px) {
    .Documents {
        display: inline-block;
    }
}
*/
