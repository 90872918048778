.Content {
    /* margin-top: 72px; */
    position: relative;
    margin: 50px auto;
    padding: 20px 0;
    text-align: center;

    max-width: 100% !important;
    overflow-x: hidden !important;
}

.Ribbon {
    top: -1px;
    right: 0px;

}

.RibbonText {
    font-size: 12px;
    text-transform: none;
}
